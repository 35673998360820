import { PlainDocument, preload } from '../../../layouts/plain-document';
import type { RouteDefinition } from '@solidjs/router';

export default function RewardsDocPage() {
	return (
		<PlainDocument breadcrumbs={[{ href: '/card', title: 'Troon Card' }]} slug="troon-card-terms-and-conditions" />
	);
}

export const route = { preload: preload('troon-card-terms-and-conditions') } satisfies RouteDefinition;
